.cmp-image {
  // margin-top: 2.25em;
}
.cmp-image__link {
}
.cmp-image__image {
  display: block;
}
.cmp-image__title {
}

.icon-hero-vehiculos{
  .cmp-image{
    img{
      width: 44px;
      margin: 29px 30% 0 30%;
    }
  }
}