.h1_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-weight: lighter;
    font-size: 3em;
    font-family: titleFont, serif;
    line-height: 4.125rem;
  }
  .cmp-title__link {
  }
}

.h2_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-family: kiaFont, serif;
    font-size: 276%;
    // font-size: 50px;
    font-weight: bold;
    line-height: normal;
  }
  .cmp-title__link {
  }
}

.kia-title-h2-margin{
  .cmp-title__text{
    margin: 213px 240px 21px 183px;
  }
}

.h3_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-weight: normal;
    font-size: 1.75em;
    line-height: 2.25rem;
  }
  .cmp-title__link {
  }
}

.h4_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-weight: normal;
    font-size: 1.125em;
  }
  .cmp-title__link {
  }
}

.h5_style {
  .cmp-title {
  }
  .cmp-title__text {
    font-weight: normal;
    font-size: 1em;
  }
  .cmp-title__link {
  }
}

.h6_style {
  .cmp-title {
  }
  .cmp-title__text {
    color: $color-shade-3;
    font-weight: normal;
    font-size: 0.625em;
    text-transform: uppercase;
  }
  .cmp-title__link {
  }
}

.h2-noticia {
  .cmp-title {
  }
  .cmp-title__text {
    font-family: kiaFont, serif;
    font-size: 2em;
    font-weight: bold;
    line-height: 40px;
  }
}


.h3-general {
  .cmp-title {
  }
  .cmp-title__text {
    font-family: kiaFont, serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 16px;
  }
}

.h4-general {
  .cmp-title {
    margin-bottom: .75rem;
    margin-top: .75rem;
  }
  .cmp-title__text {
    font-family: kiaFont, serif;
    font-size: 12px;
    font-weight: normal;
    line-height: 16px;
  }
}

.title-light-kia {
  .cmp-title {
  }
  .cmp-title__text {
    color: #fff;
  }
    .cmp-title__link {
      color: #fff;
      &:hover {
        color: #fff;
        text-decoration: underline;
      }
  }
}

.title-hero-image {
  .cmp-title {
    margin-bottom: 0;
    margin-top: 0;
  }
}

.kia-centrar-titulo{
 .cmp-title__text {
   text-align: center;
}
}

.kia-titulo-padding-left-80{
  .cmp-title__text {
    padding-left: 80px;
   }
 }

 .kia-titulo-padding-left-95{
  .cmp-title__text {
    padding-left: 95px;
   }
 }

 @media (max-width: 767px){
  .kia-titulo-padding-left-80, .kia-titulo-padding-left-95{
    .cmp-title__text {
      padding-left: 48px;
     }
   }

}

 .kia-titulo-padding-left-25{
  .cmp-title__text {
    padding-left: 25px;
   }
 }