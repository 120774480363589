// ESTILOS GENERALES

.margin-20 {
    margin: 0 20px;
}

.margin-30 {
    margin: 0 30px;
}

.margin-40 {
    margin: 0 40px;
}

.kia-video-bg-home {
    overflow: hidden;
    position: fixed;
    z-index: 0;
}

.kia-video-bg-home {
    .cmp-background-video {
        max-height: none;
        min-height: 600px;
    }
}



.kia-footer {
    position: relative;
}

// KIA NEWS //

.kia-news{
    .cmp-container-kia-news div a img{
        object-fit: cover;
    }
}






//HEADER//

/* .cmp-kia-header{
    .cmp-kia-header__header{
        .cmp-kia-header__header-menu-logo{
            width: 85px;
            filter: invert(100%) brightness(200%);
            img{
                object-fit: contain;
            }
        }
    }
} */
.cmp-kia-header__header-menu-left li > a{
    font-weight: bold;
}
@media only screen and (min-width: 751px){
.cmp-kia-header__menu-modelos__content {
    height: 341px !important;
}
.cmp-kia-header__menu-modelos_vehiculo {
    margin: 20px !important;
}
}

@media only screen and (max-width: 599px){
    .cmp-kia-header__header-menu-logo-micrositio, .cmp-kia-header__header-menu-logo-principal {
        height: auto !important;
        object-fit: contain !important;
    }
}


// VEHICULOS // 



.kia-video-bg-vehiculos {
    .cmp-background-video {
        max-height: none;
        min-height: 756px;
    }
}

.imagetab{
    .cmp-img_tab__images{
        .image-one, .image-two{
            height: 720px;
            object-fit: cover;
        }
    }
}

@media (max-width: 767px){
   .kia-video-bg-vehiculos {
        .cmp-background-video {
            max-height: none;
            min-height: 555px;
        }
    } 

    .imagetab{
        .cmp-img_tab__images{
            .image-one, .image-two{
                height: 240px;
                object-fit: cover;
            }
        }
    }

    .kia-cmp__progress-tabs{
        height: 530px !important;
    }

    .cmp-kia-carousel-container {
        margin-left: 44px !important;
    }

    .cont-hero-vehiculos{
        .cmp-container{
            .aem-Grid{
                display: flex;
                justify-content: space-around;
            }
        }
    }

    .kia-download-tile{
        & > div{
            display: flex;
            justify-content: center;
            margin-bottom: 30px;
        }
        .cpm-download-title-container{
            min-height: 110px;
            min-width: 247px;
        }
        .content {
            display: none;
        }
    }
}


// CARUSEL TILES // 

.cmp-kia-carousel-container .swiper-slide{
    box-shadow: none;
}
.cmp-carousel-tiles_btn {
    color: #fff !important;
    background-color: #05141F !important;
    border: none !important;

}

// IMAGEN POP UP // 

.cmp-kia-modal-window-popup{
    .cmp-kia-contenedor-popup{
        img {
            object-fit: cover;
        };
    }
}

.kia-popup{
    .cmp-kia-contenedor-popup{
        div{
            .cmp-kia-container-popup{
                margin: 0 6px;
            }
        }
    }
}

.cmp-kia-modal-window-popup > div {
    top: 58% !important;
}

.cmp-kia-modal-window-popup-content {
    background: rgb(0,0,0);
background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(255,255,255,0) 42%, rgba(0,0,0,1) 88%);
}

.cmp-popup-titulo-imagen, .cpm-download-title-container .title{
    line-height: 30px;
}

.cmp-kia-container-popup .cmp-kia-interior-popup .cmp-kia-capa-popup {
    height: calc(100% - 6px) !important;   
    }

// IMAGE TAB // 

.img-left .title-tab_one, .img-left .title-tab_two {
    height: 80px;
}

.imagetab .cmp-img_tab__images .image-one, .imagetab .cmp-img_tab__images .image-two{
    height: 500px;
}

// MENU STICKY VEHICULOS //  

.kia-cmp__sticky-menu__links-ul{
    width: 92% !important;
    margin-left: 30px !important;
}

.kia-cmp__sticky-menu{
    height: 106px !important;
    top: 0 !important;
}

@media only screen and (max-width: 800px){
    .kia-cmp__sticky-menu__mob-button{
        margin-left: 10% !important;
    }
    .kia-cmp__sticky-menu__title-img__section {
        min-width: 74px;
    }
}


// VERSIONES Y PRECIOS //  

.kia-cmp__version-precio__content__image{
    bottom: 298px !important;
}

.kia-cmp__version-precio__content__description{
    top: 41px;
    position: relative;
}




// RECALL // 

@media (max-width: 800px){
    .cmp-kia-recall-form__vin{
        width: 165px !important;
    }
    .cmp-kia-recall-form__vin-description{
        width: 190px !important;
    }

    .cmp-kia-recall-form__btn-consultar-container {
        margin: 44px 0 30px 0 !important;
        padding: 0 7% !important;
    }
}

// VIDEO POP UP//

.cmp-kia-container-popup-video .cmp-kia-interior-popup-video {
    height: 144px !important;
}

.cmp-kia-container-popup-video .cmp-kia-interior-popup-video .cmp-kia-imagen-popup-video {
    object-fit: cover;
}

.cmp-kia-modal-window-popup-video > div {
    top: 58% !important;
}


// CALCULADORA VERDE // 

.kia-green-calculator-mobile-content .kia-green-calculator-mobile-header h3 {
    line-height: 61px;
}

.kia-green-calculator-results .kia-green-calculator-result .kia-green-calculator-result-value {
    font-size: 2.5rem !important;
}

@media (max-width: 800px){
    .kia-green-calculator-results .kia-green-calculator-result .kia-green-calculator-result-value {
        font-size: 1rem !important;
    }
}

// TAB CON BARRA DE PROGRESO //

.kia-cmp__progress-tabs__images__img {
    object-fit: contain;
}


// HEADER GENERAL 

.header-blanco-kia {

    .cmp-kia-header .cmp-kia-header__header .cmp-kia-header__header-menu-logo, .cmp-kia-header__header-menu-search{
        filter: none;
    }

    .cmp-kia-header__header-menu-left li>a, .cmp-kia-header__header-menu-right li>a {
        color: #05141F;
    }

    .cmp-kia-header {
        
        background-color: white;
    }



    /* .cmp-kia-header__preheader {
        position: absolute;
    } */
    @media only screen and (max-width: 1050px){
        .cmp-kia-header__header-button-mobile {
            filter: brightness(1);
        }
    } 
}



// MENU FLOTANTE // 

.no-menu-flotante{
        .kia-menu-flotante {
            display: none;
    }
}


.bg-test {
    background: blue;
}

.bg-test2 {
    background: green;
}

//= FIX PRESALE IFRAME
.fix-preventa .kia-cmp__showroom-iframe {
    height: 650px;
    border: 0px;
}
@media (max-width: 1050px){ 
    .fix-preventa .kia-cmp__showroom-iframe {
        height: 680px;
    }
}
@media (max-width: 420px) { 
    .fix-preventa .kia-cmp__showroom-iframe {
        height: 820px;
    }
 }
