.cmp-button {
  width: fit-content;
  height: fit-content;
  margin: 0.5em 0.5em;
  padding: 0.5625em 1em 0.6875em 1em;
  border-style: solid;
  border-width: 0.125em;
  border-radius: 1.56em;
}
.cmp-button__text {
  font-weight: 300;
  text-decoration: none;
}
.cmp-button__icon {
}

.kia-button-dark{
  .cmp-button{
    float: none;
    border-radius: 0;
    background-color: #05141F;
    border-color: #05141F;
    margin: 8px 0;
    padding: 21.5px 40px;
    font-family: kiaFont, serif;
    font-weight: bold;
    color: #fff;
  }
}

.kia-button-light{
  .cmp-button{
    float: none;
    border-radius: 0;
    background-color: #fff;
    border-color: #05141F;
    margin: 8px 0;
    padding: 21.5px 40px;
    font-family: kiaFont, serif;
    font-weight: bold;
    color: #05141F;
  }
}

.kia-button-dark-transparent{
  .cmp-button{
    float: none;
    border-radius: 0;
    background-color: transparent;
    border-color: #ffffff;
    margin: 8px 0;
    padding: 21.5px 40px;
    font-family: kiaFont, serif;
    font-weight: bold;
    color: #fff;
  }
}

a.cmp-button {
  text-decoration: none;
}

.kia-button-dark, .kia-button-light, .kia-button-dark-transparent{
  width: auto !important;
}

.kia-button-margin-left {
  margin-left: 10px !important;
}