
//== Defaults

html, body {
  margin: 0;
  color: $color-text;
  font-size: $font-size;
  font-family: kiaFont, serif;
  line-height: $font-height;
  background: white;
}

a {
  color: $color-link;
}

p {
  margin-block: 0.75rem;
  line-height: 28px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block: 0;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

//== Grid

$max_col: 12;

// Default breakpoint
.aem-Grid {
  @include generate-grid(default, $max_col);
}

// Phone breakpoint
@media (max-width: 768px) {
  .aem-Grid {
    @include generate-grid(phone, $max_col);
  }
}


@media (max-width: 1049px) {
  .hidden-menu-mobile {
    display: none;
  }
}


.padding-20 {
      padding: 20px;
}


//ESCONDER ELEMENTOS EN MOBILE Y DESKTOP //@at-root
@media (min-width: 801px) {
.hide-desktop {
  display: none !important;
}
}

@media (max-width: 800px) {
  .hide-mobile {
    display: none !important;
  }
}