.kia-subtitle{
  .cmp-text {
    p{
      font-family: kiaFont, serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
    }
  } 
}


