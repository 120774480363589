.cmp-form-button {
  float: right;
  width: fit-content;
  height: fit-content;
  margin-top: 0.5em;
  padding: 0.5em 1em;
  color: $color-text-inverted;
  background-color: $color-accent;
  border: 0.125em solid $color-accent;
  border-radius: 1.56em;

  &:hover {
    background-color: $color-accent-darker;
    border-color: $color-accent-darker;
    cursor: pointer;
  }
  &:focus {
    background-color: $color-accent-lighter;
    border-color: $color-background;
    outline: none;
    box-shadow: 0 0 0 0.125em $color-accent-lighter;
  }
  &:disabled {
    background-color: $color-shade-1;
    border-color: $color-shade-1;
  }
}

.kia-form-button{
  .cmp-form-button{
    float: none;
    border-radius: 0;
    background-color: #05141F;
    border-color: #05141F;
    margin: 0;
    padding: 21.5px 40px;
    font-family: kiaFont, serif;
    font-weight: bold;
  }
}

.kia-button-margin{
  .cmp-form-button{
    margin-left: 75px;
  }
}