.cmp-tabs {
  margin: 0.5em;
}

.cmp-tabs__tablist {
}
.cmp-tabs__tab {
  height: 2.625em;
  margin: 0;
  padding: 0.5625em 1em 0.6875em 1em;
  color: $color-shade-3;
  font-size: 1em;
  outline: none;
  &:hover {
    color: $color-text;
  }
  //&:focus {
  //  border-radius: 0.25em;
  //  border: 0.125em solid $color-accent-lighter;
  //}
}
.cmp-tabs__tab--active {
  color: $color-text;
  border: none;
  &:focus {
    //border-radius: 0;
    border: none;
  }
}

.cmp-tabs__tabpanel {
}
.cmp-tabs__tabpanel--active {
  padding-right: 0.5em;
  padding-left: 0.5em;
}


// Estilos Páginas Generales

.tabs-full-image-template{
  .cmp-tabs__tablist {
    display:flex;
    flex-wrap:wrap;
    padding-left:0;
    list-style:none;
    justify-content: flex-end;
  }
  .cmp-tabs__tab {
    height: 2.625em;
    margin: 0 10px;
    padding: 0.5625em 1em 0.6875em 1em;
    color: #9BA1A5;
    font-size: 16px;
    font-weight: 300;    
    outline: none;
    height: 34px;
    &:hover {
      color: $color-text;
      border-bottom: #05141f 2px solid;
    }
    //&:focus {
    //  border-radius: 0.25em;
    //  border: 0.125em solid $color-accent-lighter;
    //}
  }
  .cmp-tabs__tab--active {
    color: $color-text;
    
    font-size: 16px;
    font-weight: 700;
    border-bottom: $color-text 3px solid;
    height: 40px;
    &:focus {
      //border-radius: 0;
      border: none;
      border-bottom: $color-text 3px solid;
    }
  }
  
  .cmp-tabs__tabpanel {
  }
  .cmp-tabs__tabpanel--active {
    padding-right: 0.5em;
    padding-left: 0.5em;
  }
}