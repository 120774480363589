.container-ancho-33 {
  width: 33% !important;

  .cmp-container {
    width: 100% !important;
  }
}

.container-ancho-25 {
  width: 25% !important;

  .cmp-container {
    width: 100% !important;
  }
}

.container-ancho-50 {
  width: 50% !important;

  .cmp-container {
    width: 100% !important;
  }
}

.container-center-vertical {
  .cmp-container {
    margin: 32% 0;
  }
}

.container-last-noticias {
  .cmp-container {
    margin-bottom: 50px;
  }
}

.contenido-noticia {
  .cmp-text {
    p {
      font-family: kiaFont, serif;
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
      padding: 0;
      word-break: break-word;
    }
  }
}

.subtitulo-noticia {
  .cmp-text {
    p {
      font-family: kiaFont, serif;
      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
    }
  }
}

.parrafo-kia-2 {
  .cmp-text {
    p {
      font-family: kiaFont, serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
    }
  }
}

.parrafo-kia-hero-vehiculos {
  .cmp-text {
    p {
      font-family: kiaFont, serif;
      font-size: 12px;
      line-height: 14px;
    }
  }
}

.texto-blanco-kia {
  .cmp-text {
    p {
      color: #fff;
    }

    & a {
      color: #fff;
      text-decoration: none;
      border: 0.125em solid $color-transparent;
      border-radius: 0.25em;

      &:hover {
        color: #fff;
        text-decoration: underline;
        text-decoration-thickness: 0.125em;
        text-decoration-color: #fff;
        text-decoration-style: solid;
      }

      &:focus {
        text-decoration: underline;
        border-color: $color-accent;
        outline: none;
        text-decoration-thickness: 0.125em;
        text-decoration-color: $color-accent;
        text-decoration-style: solid;
      }
    }
  }

}

.container-table-color-format {
  .cmp-container {
    tbody {
      tr:nth-child(even) {
        background: #D0E4F5;
      }
    }
  }
}

.container-table-resp {
  .cmp-container {
    .table {
      overflow-x: auto;
    }
  }
}

.cmp-container {
  .table {
    tr {
      p:nth-child(2) {
        color: #093;
      }
    }
  }
}

.kia-base-container {
  max-width: 1920px;
  margin: 0 auto;
}

// MARGENES CONTENEDOR


.padding-30-text {
  .cmp-container {
    &>div {
      padding: 0 30px;
    }
  }
}

.padding-40-text {
  .cmp-container {
    &>div {
      padding: 0 40px;
    }
  }
}

.padding-50-text {
  .cmp-container {
    &>div {
      padding: 0 50px;
    }
  }
}

.padding-80-text {
  .cmp-container {
    &>div {
      padding: 0 80px;
    }
  }
}

.padding-25-percent {
  .cmp-container {

    .text,
    .title {
      padding: 0 25%;
    }

    .aem-Grid {
      &>div {
        padding: 0 25%;
      }
    }
  }
}


.padding-home-gradient {
  &>.cmp-container {
    background: rgb(255, 255, 255);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 50%);
  }
}

.centrar-elementos-flex {
  .cmp-container {
    .aem-Grid {
      display: flex;
      justify-content: center;
    }
  }
}

@media (min-width: 800px) {
  .contenido-vehiculos {
    position: relative;
    bottom: 100px;
  }
}

@media (max-width: 767px) {

  .padding-25-percent,
  .padding-50-text,
  .padding-40-text,
  .padding-30-text,
  .padding-80-text {
    .cmp-container {

      .text,
      .title {
        padding: 0 30px;
      }

      .aem-Grid {
        &>div {
          padding: 0 30px;
        }
      }
    }
  }

  .padding-80-text {
    .cmp-container {
      &>div {
        padding: 0;
      }
    }
  }
}

.kia-menu-hero-bar {
  position: relative;
}

.padding-30-text {
  .cmp-container {
    background-size: 100% 100%;
    height: 100%;
  }
}

.img-size-60 {
  .cmp-image__image {
    width: 60px;
    margin: 0 auto;

  }
}

.bloque-home-pequeño {
  .cmp-container {
    height: 360px;

    .aem-Grid {
      position: relative;
      top: 40%;
    }
  }
}

@media (max-width: 767px) {
  .bloque-home-pequeño {
    .cmp-container {
      height: 188px;

      .aem-Grid {
        position: relative;
        top: 30%;
      }
    }
  }
}

.bloque-home-mediano {
  .cmp-container {
    height: 360px;
  }
}

.bloque-home-grande {
  .cmp-container {
    height: 720px;
  }
}


/* .issue-carousel-tiles{
  .cmp-container{
    .aem-Grid{
      .featuredblock div{
        .cmp-kia-featured-block:first-child{
          display: none;
        }
      }
    }
  }
}

.featured-block-mobile{
  .cmp-container{
    .aem-Grid{
      .featuredblock{
        margin: 0 95px;
        width: auto;
      }
    }
  }
} */

.cont-hero-vehiculos {
  position: relative;
  bottom: 143px;

  &>.cmp-container {
    max-height: 144px;
    background-color: rgb(0, 0, 0, 0.6) !important;

  }
}

@media (max-width: 767px) {
  .featured-block-mobile {
    .cmp-container {
      .aem-Grid {
        .featuredblock {
          margin-left: 0;
          width: 100%;
        }
      }
    }
  }

  .kia-news-mobile {
    .cmp-container {
      .aem-Grid {
        margin: 0 22px;
        width: auto;

        .carousel {
          .cmp-carousel {
            margin: 0;
          }
        }
      }
    }
  }

  .cont-hero-vehiculos {
    position: initial;

    .cmp-container {
      .cmp-kia-container-popup-video .cmp-kia-interior-popup-video {
        width: 152px;
        height: 105px;
      }

      .aem-Grid {
        .kia-popup-video:first-child .cmp-kia-container-popup-video .cmp-kia-interior-popup-video:first-child {
          position: relative;
          right: 58px;
        }

        .kia-popup-video:last-child .cmp-kia-container-popup-video .cmp-kia-interior-popup-video:first-child {
          position: relative;
          right: 26px;
        }
      }

    }
  }
}


.kia-news-dark .kia-news div .cmp-container-kia-news .cmp-kia-titulo p {
  color: white;
}


.kia-news-dark .kia-news div .cmp-container-kia-news .cmp-kia-titulo-noticia-uno {
  color: white;
}

.kia-news-dark .kia-news div .cmp-container-kia-news .cmp-kia-descripcion-imagen-uno p {
  color: white;
}

.kia-news-dark .kia-news div .cmp-container-kia-news #progressBar {
  width: 222%;
  height: 3px;
  appearance: none;
  -webkit-appearance: none;
}

.kia-news-dark .kia-news div .cmp-container-kia-news progress[value]::-webkit-progress-bar {
  background-color: rgba(255, 255, 255, 0.612);
}

.kia-news-dark .kia-news div .cmp-container-kia-news progress[value]::-webkit-progress-value {
  background-color: white;
}

.kia-news-dark .kia-news div .cmp-container-kia-news .btn-noticias-uno {
  width: 20%;
  height: 60px;
  border: none;
  background: #ffffff;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-top: 10px;
}

.kia-news-dark .kia-news div .cmp-container-kia-news .btn-noticias-uno a {
  color: #05141F;
}

.container-ancho-33 .cmp-container .kia-popup .cmp-kia-interior-popup img {
  height: 128px !important;
  object-fit: cover;
}

.container-ancho-25 .cmp-container .kia-popup .cmp-kia-interior-popup img,
.container-ancho-50 .cmp-container .kia-popup .cmp-kia-interior-popup img {
  height: 236px !important;
  object-fit: cover;
}

.container-ancho-25,
.container-ancho-50,
.container-ancho-33 {
  padding: 0 !important;
}