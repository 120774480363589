.cmp-text {
  margin: 0.75rem 0 0 0;
  color: $color-text;
  font-weight: normal;
  font-size: 1em;
  & b {
    font-weight: bold;
  }
  & p a {
    color: $color-link;
    text-decoration: none;
    border: 0.125em solid $color-transparent;
    border-radius: 0.25em;
    &:hover {
      color: #05141F;
      text-decoration: underline;
      text-decoration-thickness: 0.125em;
      text-decoration-color: #05141F;
      text-decoration-style: solid;
    }
    &:focus {
      text-decoration: underline;
      border-color: #05141F;
      outline: none;
      text-decoration-thickness: 0.125em;
      text-decoration-color: #05141F;
      text-decoration-style: solid;
    }
  }
  & blockquote {
    margin: 0.75rem 0;
    font-size: 1.75em;
    font-family: titleFont, serif;
    font-style: italic;
    line-height: 2.375rem;
    margin-inline: 0;
  }
  & ul li::marker {
    content: '-  ';
  }
}


.kia-subtitle{
  .cmp-text {
    p{
      font-family: kiaFont, serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
    }
}
}
