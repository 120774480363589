.cmp-carousel {
  margin: 0;
}
.cmp-carousel__content {
  position: relative;
}
.cmp-carousel__item {
}
.cmp-carousel__actions {
  position: absolute;
  right: 0.5em;
  bottom: 1.75em;
  display: flex;
  flex-direction: row;
  float: right;
}
.cmp-carousel__action {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0.125em;
  background-color: $color-shade-1;
  border-width: 0;
  border-radius: 0.25em;
  box-shadow: none;
  & .cmp-carousel__action-icon {
    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: inline-block;
      width: 24px;
      height: 24px;
      background-color: $color-shade-3;
      transform: translate(-50%, -50%);
      content: '';
      mask-size: cover;
    }
  }
  &:focus {
    outline: none;
  }
}
.cmp-carousel__action--disabled {
}
.cmp-carousel__action--previous {
  & .cmp-carousel__action-icon {
    &:before {
      mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00ODUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDg1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNLjI5MywxMy4wMjFhMSwxLDAsMCwxLDAtMS40MTVsNC45NS00Ljk1TC4yOTMsMS43MDdBMSwxLDAsMCwxLDEuNzA3LjI5M0w3LjM2NCw1Ljk1YTEsMSwwLDAsMSwwLDEuNDE0TDEuNzA3LDEzLjAyMWExLDEsMCwwLDEtMS40MTUsMFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUwMSA5OC42NTcpIHJvdGF0ZSgxODApIi8+PC9nPjwvc3ZnPg==');
    }
  }
}
.cmp-carousel__action--next {
  & .cmp-carousel__action-icon {
    &:before {
      mask: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48ZGVmcz48c3R5bGU+LmEsLmJ7ZmlsbDojNjA3ZDhiO30uYXtvcGFjaXR5OjA7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NDUgLTgwKSI+PHJlY3QgY2xhc3M9ImEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDQ1IDgwKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNMzAuNzA3LTMuNTY1YTEsMSwwLDAsMSwwLTEuNDE0bDQuOTQ5LTQuOTUtNC45NDktNC45NWExLDEsMCwwLDEsMC0xLjQxNCwxLDEsMCwwLDEsMS40MTQsMGw1LjY1Nyw1LjY1NmExLDEsMCwwLDEsLjI5My43MDcsMSwxLDAsMCwxLS4yOTMuNzA3TDMyLjEyMS0zLjU2NWExLDEsMCwwLDEtLjcwNy4yOTNBMSwxLDAsMCwxLDMwLjcwNy0zLjU2NVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQyMi45MjkgMTAxLjkyOSkiLz48L2c+PC9zdmc+');
    }
  }
}
.cmp-carousel__action--pause {
  display: none;
}
.cmp-carousel__action--play {
  display: none;
}
.cmp-carousel__action-icon {
}
.cmp-carousel__action-text {
  display: none;
}
.cmp-carousel__indicators {
  height: 20px;
}
.cmp-carousel__indicator {
  width: 2.5rem;
  height: 0.125rem;
  margin: auto 0.125rem 0 0.125rem;
  background-color: $color-shade-1;
  border-radius: 0.125rem;
}
.cmp-carousel__indicator--active {
  background-color: $color-shade-4;
}


.kia-carousel-no-indicators, .kia-carousel-no-indicators-dealers {
  .cmp-carousel {
    .cmp-carousel__content{
      .cmp-carousel__indicators{
        display: none;
      }
      .cmp-carousel__actions {
        top: 50%;
        width: 100%;
        justify-content: space-between;
      }
      .cmp-carousel__action{
        background: var(--unnamed-color-05141f) 0% 0% no-repeat padding-box;
        background: #05141F 0% 0% no-repeat padding-box;
        opacity: 0.4;
        border-radius: 28px;
        width: 55px;
        height: 55px;
      }
      .cmp-carousel__action--previous .cmp-carousel__action-icon:before {
        mask: url("https://www.kia.cl/content/dam/indumotora/general/iconos/arrow-left-carousel.png");
        width: 32px;
      }
      .cmp-carousel__action--next .cmp-carousel__action-icon:before {
        mask: url("https://www.kia.cl/content/dam/indumotora/general/iconos/arrow-right-carousel.png");
        width: 32px;
      }
    }  
  }
}
.cmp-carousel__actions {
  height: 0;
}
.kia-carousel-no-arrows {
  .cmp-carousel {
    .cmp-carousel__content{
      .cmp-carousel__actions {
       display: none;
      }
    }  
  }
} 

// CARRUSEL ARROWS //  
@media (max-width: 767px){
  .kia-carousel-no-indicators .cmp-carousel .cmp-carousel__content .cmp-carousel__actions {
      top: 150px;
      right: 0px;
  }
}   

// CARRUSEL DEALERS //  
@media (max-width: 767px){
  .kia-carousel-no-indicators-dealers .cmp-carousel .cmp-carousel__content .cmp-carousel__actions {
      top: auto;
      right: 0px;
  }
}   


.kia-carousel-no-indicators-dealers .cmp-carousel .cmp-carousel__content .cmp-carousel__actions, .kia-carousel-no-indicators .cmp-carousel .cmp-carousel__content .cmp-carousel__actions {
  width: 99% !important;
}

.fakeclass {
  height: auto;
}