.cmp-separator {
}
.cmp-separator__horizontal-rule {
  background-color: $color-shade-1;
  border: none;
}

.separator-50 {
    height: 40px;  
}

.separator-transparent {
    background-color: transparent;
}

.separator-transparent {
    .cmp-separator__horizontal-rule-personalizado{
    background-color: transparent;
}
}



.separator-white {
    background-color: #fff;
}

.separator-250 {
    height: 250px;  
}

.separator-500 {
    height: 500px;  
}